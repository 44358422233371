/* eslint-disable react/prop-types */
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import Icon from "@mui/material/Icon";
import { Done, Sync, CloseOutlined } from "@mui/icons-material";

import AlipayIcon from "layouts/asset/cash-record/components/AlipayIcon";
import BankCardIcon from "layouts/asset/cash-record/components/BankCardIcon";
import WeChatIcon from "layouts/asset/cash-record/components/WeChatIcon/index";

function getIcon(rows) {
  switch (rows.payee_type) {
    /* 银联 */
    case "bank": {
      return <BankCardIcon />;
    }
    /* 支付宝 */
    case "alipay": {
      return <AlipayIcon />;
    }
    /* 微信 */
    case "wechat": {
      return <WeChatIcon />;
    }
    default: {
      return null;
    }
  }
}
function getWStatus(value) {
  let wStatus = value;
  wStatus += "";
  let color;
  let icon;
  let status;
  if (wStatus === "0") {
    /* 待处理 */
    icon = <Sync />;
    color = "secondary";
    status = "处理中";
  } else if (wStatus === "2") {
    /* 失败 */
    icon = <CloseOutlined />;
    color = "primary";
    status = "失败";
  } else {
    /* 成功 1 */
    icon = <Done />;
    color = "success";
    status = "完成";
  }
  return <StatusCell icon={icon} color={color} status={status} />;
}

const dataTableData = {
  columns: [
    {
      Header: "时间",
      accessor: "w_time",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "提现金额",
      accessor: "amount",
      align: "right",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    /* 占位 */
    {
      Header: "",
      accessor: "seat1",
      width: "5%",
      Cell: () => "",
    },
    {
      Header: "收款人",
      accessor: "payee",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "收款账户",
      accessor: "payee_account",
      width: "20%",
      Cell: ({ row: { values }, value }) => (
        <CustomerCell>
          <MDBox display="flex" alignItems="center">
            <Icon
              fontSize="18rem"
              sx={{
                marginRight: "5px",
              }}
            >
              {getIcon(values)}
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {value}
            </MDTypography>
          </MDBox>
        </CustomerCell>
      ),
    },
    {
      Header: "账户类型",
      accessor: "payee_type",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "提现状态",
      accessor: "w_status",
      width: "10%",
      Cell: ({ value }) => getWStatus(value),
    },
    {
      Header: "备注",
      accessor: "remark",
      minWidth: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
  ],
  rows: [],
};

export default dataTableData;
