import React from "react";
import { getAuthorization } from "utils/storage/local";
import Guard from "./guard";

async function beforeRouteEntry({ pathname }) {
  const SIGN_IN = "/authentication/sign-in";
  const authentication = getAuthorization();
  if (!authentication && !pathname.includes(SIGN_IN)) {
    return SIGN_IN;
  }
  return pathname;
}
function transformRoutes(routes) {
  const list = [];
  const clouse = (routeList) => {
    // eslint-disable-next-line consistent-return
    (routeList ?? []).forEach((route) => {
      if (route.collapse) {
        return clouse(route.collapse);
      }
      if (route.type !== "divider") {
        const obj = { ...route };
        obj.path = route.route;
        obj.element = (
          <Guard element={route.component} meta={route.meta} beforeRouteEntry={beforeRouteEntry} />
        );

        delete obj.name;
        delete obj.component;
        delete obj.route;
        delete obj.key;
        delete obj.zhPath;

        list.push(obj);
      }
    });
  };

  clouse(routes);
  return list;
}

export default transformRoutes;
