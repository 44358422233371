import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import Header from "layouts/agent/overview/components/Header";
import Content from "layouts/agent/overview/components/Content";

function Overview(props) {
  const { meta } = props;

  return (
    <DashboardLayout>
      <DashboardNavbar zhPath={meta.zhPath} />
      <MDBox mb={4} />
      <Header />
      <Content />
    </DashboardLayout>
  );
}

Overview.propTypes = {
  meta: PropTypes.shape({
    zhPath: PropTypes.string,
  }).isRequired,
};
export default Overview;
