/* eslint-disable react/prop-types */
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";

const dataTableData = {
  columns: [
    {
      Header: "日期",
      accessor: "r_date",
      width: "25%",
      Cell: ({ value }) => {
        const [valueC, TOTAL] = (value || "").split(">>SI<<");
        return <DefaultCell value={valueC} fontWeight={TOTAL === "TOTAL" ? "bold" : "medium"} />;
      },
    },
    {
      Header: "GMV",
      accessor: "gmv",
      align: "right",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "当日佣金",
      accessor: "revenue",
      align: "right",
      width: "25%",
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    /* 占位 */
    {
      Header: "",
      accessor: "seat1",
      width: "25%",
      Cell: () => "",
    },
  ],

  rows: [],
};

export default dataTableData;
