import SvgIcon from "@mui/material/SvgIcon";

function RoundIcon() {
  return (
    <SvgIcon>
      <path
        d="M2.71579 10.2586L0.0631578 10.7652C-6.11732e-08 11.2084 0 11.5884 0 12.0317C0 14.9446 1.01053 17.7309 2.90526 19.8839L4.8 18.2375C3.34737 16.5277 2.52632 14.3113 2.52632 12.0317C2.52632 11.4617 2.58947 10.8285 2.71579 10.2586ZM12 0C8.58947 0 5.55789 1.45646 3.34737 3.73615L5.11579 5.50923C6.88421 3.67282 9.28421 2.53298 12 2.53298C12.5684 2.53298 13.2 2.59631 13.7684 2.72296L14.2105 0.253298C13.5158 0.0633246 12.7579 0 12 0ZM21.2842 13.8047L23.9368 13.2982C24 12.8549 24 12.4749 24 12.0317C24 9.24538 23.0526 6.52243 21.2842 4.36939L19.3263 5.95251C20.7158 7.66227 21.4737 9.8153 21.4737 11.9683C21.4737 12.6016 21.4105 13.2348 21.2842 13.8047ZM18.8842 18.5541C17.1158 20.3905 14.7158 21.5303 12 21.5303C11.4316 21.5303 10.8 21.467 10.2316 21.3404L9.78947 23.81C10.5474 23.9367 11.3053 24 12 24C15.4105 24 18.4421 22.5435 20.6526 20.2639L18.8842 18.5541Z"
        fill="white"
      />
      <path
        d="M16.3579 1.83641L23.8737 2.8496L17.6842 9.05541L16.3579 1.83641ZM7.51579 22.1003L0 21.0871L6.18947 14.8813L7.51579 22.1003ZM1.83158 7.53562L2.84211 0L9.03158 6.2058L1.83158 7.53562Z"
        fill="white"
      />
      <path d="M22.0428 16.4009L21.0322 23.9365L14.8428 17.7307L22.0428 16.4009Z" fill="white" />
    </SvgIcon>
  );
}
export default RoundIcon;
