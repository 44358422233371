import ReactDOM from "react-dom";
import SiSocialbar from "components/SiSocialbar";

const message = ({ color, content, title, duration }) => {
  const dom = document.createElement("div");
  const JSXdom = <SiSocialbar color={color} title={title} content={content} duration={duration} />;
  ReactDOM.render(JSXdom, dom);
  document.body.appendChild(dom);
};

export default message;
