/* eslint-disable react/no-unknown-property */

import { SvgIcon } from "@mui/material";

function Asset(prop) {
  return (
    <SvgIcon {...prop} viewBox="0 0 1024 1024">
      <path
        d="M917.6 585s-54.9 53.6-60.5 59.1c-5.7 5.5-24.5 4.8-24.5 4.8s-50-5.5-57.3-12.4c-7.2-7-12.1-57.8-12.1-57.8s-1.4-14.2 5.8-21.2c7.2-6.9 60.7-58.9 60.7-58.9s6.9-7.5-3.1-7.5c-32.2-0.1-97.4 0.6-129 31.3l-6.5 6.3c-37.3 36.5-39.6 91.4-9.6 130.7l-185 162.4c-14.7 14.3-34 53.2 0 86.3 34 33.1 74 14.3 88.7 0 0 0 121-128.6 131.3-139.8 10.4-11.2 4.9-23.1 4.9-23.1s-21.3-28.9-32.8-46.1c-6.2-9.5-3.2-10.7 5.6-5.1 24.5 15.2 53 32.6 57.9 34.9l0.3-0.3c40.4 29.5 96.8 27.3 134.4-9.2l6.5-6.3c30.7-29.7 31.6-92.7 31.7-124.3 0.2-10.3-7.4-3.8-7.4-3.8zM545.8 889.6c-17.1 0-31.1-13.5-31.1-30.2s13.9-30.2 31.1-30.2c17.1 0 31.1 13.5 31.1 30.2s-13.9 30.2-31.1 30.2zM444.4 553.8c52.4 0 101.9-5.2 146.4-14.2 28.3-75.6 102.7-129.8 190.1-129.8 3.1 0 6.2 0.3 9.3 0.5 0.2-2.1 0.7-4.2 0.7-6.3V289.4c0 82.7-155.1 149.8-346.5 149.8s-346.5-67-346.5-149.8V404c0.1 82.7 155.2 149.8 346.5 149.8z m0-155.7c191.4 0 346.5-67 346.5-149.8 0-82.8-155.1-149.8-346.5-149.8s-346.5 67-346.5 149.8c0.1 82.8 155.2 149.8 346.5 149.8z m0 311.3c19.3 0 38-0.8 56.3-2.1l78.8-76.5c0.2-0.2 0.4-0.4 0.6-0.5-1-8-1.5-15.9-1.5-23.9 0-7.9 0.7-15.7 1.6-23.4-41.7 7.7-87.6 12-135.7 12C253 595 97.9 527.9 97.9 445.2v114.6c0.1 82.5 155.2 149.6 346.5 149.6zM98 600.7v114.5c0 78.8 140.5 143.2 318.9 149.2-4.4-37.5 2.3-77.9 21.8-96.9l17.8-17.3c-4.1 0.1-8 0.3-12.1 0.3-191.3 0-346.4-67-346.4-149.8z m0 0"
        p-id="10735"
      />
    </SvgIcon>
  );
}
export default Asset;
