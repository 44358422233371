import { useState, useMemo } from "react";

import { Grid, Icon } from "@mui/material";

import MDBox from "components/MDBox/index";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import MoneyIcon from "layouts/project/overview/components/Dashboard/components/MoneyIcon";
import DisplayIcon from "layouts/project/overview/components/Dashboard/components/DisplayIcon";

import pxToRem from "assets/theme/functions/pxToRem";

import getStats from "api/project/stats";

function Dashboard() {
  const [data, setData] = useState({
    revenue_week: 0,
    revenue_unsettle: 0,
    uv_today: 0,
    revenue_estimate_today: 0,
  });

  useMemo(async () => {
    const result = await getStats();
    if (result) {
      setData({
        revenue_week: result?.summary?.revenue_week,
        revenue_unsettle: result?.summary?.revenue_unsettle,
        uv_today: result?.summary?.uv_today,
        revenue_estimate_today: result?.summary?.revenue_estimate_today,
      });
    }
  }, []);
  return (
    <MDBox mb={6} mt={6}>
      <Grid container spacing={3} rowSpacing={5}>
        <Grid item xs={12} md={6} xls={3}>
          <ComplexStatisticsCard
            color="dark"
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(26)} !important`,
                }}
              >
                <MoneyIcon />
              </Icon>
            }
            title="本周收益"
            count={`￥${data.revenue_week}`}
            percentage={{
              color: "dark",
              amount: "",
              label: "不含未结算收益",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xls={3}>
          <ComplexStatisticsCard
            color="primary"
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(26)} !important`,
                }}
              >
                <MoneyIcon />
              </Icon>
            }
            title="待结算收益"
            count={`￥${data.revenue_unsettle}`}
            percentage={{
              color: "dark",
              amount: "",
              label: "预估值,请以实际结算数据为准",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xls={3}>
          <ComplexStatisticsCard
            color="success"
            icon={<DisplayIcon />}
            title="今日展现"
            count={data.uv_today}
            percentage={{
              color: "dark",
              amount: "",
              label: "链接访问人数(UV)",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xls={3}>
          <ComplexStatisticsCard
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(26)} !important`,
                }}
              >
                <MoneyIcon />
              </Icon>
            }
            title="今日预计收益"
            count={`￥${data.revenue_estimate_today}`}
            percentage={{
              color: "dark",
              amount: "",
              label: "预估值，请以实际收益为准",
            }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}
export default Dashboard;
