import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

import { formValidator, formValidators } from "utils/form-validator";

import signIn from "api/authentication/sign-in";
import { useRuningStateController, setSignIn } from "context/runing-state";

import useDebounce from "utils/debounce";
import { HTTP_DELAY } from "data/standard";

import handleUserActionSignIn from "utils/handle-user-action/sign-in";

function Cover() {
  const navigate = useNavigate();
  const [, dispatch] = useRuningStateController();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorHelpText, setEmailHelpText] = useState("");
  const [passwordHelpText, setPasswordHelpText] = useState("");

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async () => {
    const [validateEmail, validatePassword] = formValidators(
      [{ value: email }, { value: password }],
      [
        [
          {
            key: "email",
            errorText: "邮件格式错误",
          },
        ],
        [
          {
            key: "password",
            errorText: "密码长度最少6位",
          },
        ],
      ]
    );
    if (validateEmail || validatePassword) {
      setEmailError(Boolean(validateEmail));
      setPasswordError(Boolean(validatePassword));
      setEmailHelpText(validateEmail);
      setPasswordHelpText(validatePassword);
    } else {
      const result = await signIn({
        method: "post",
        body: JSON.stringify({
          log_type: "email",
          email,
          password,
        }),
      });
      if (result) {
        setSignIn(dispatch, true);
        handleUserActionSignIn({
          authorization: result.authorization,
          userInfo: result.user,
        });
        navigate("/project/project-overview");
      }
    }
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    const validate = formValidator(e.target, [
      {
        key: "email",
        errorText: "邮件格式错误",
      },
    ]);
    setEmailError(Boolean(validate));
    setEmailHelpText(validate || "");
    setEmail(value);
  };
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    const validate = formValidator(e.target, [
      {
        key: "password",
        errorText: "密码长度最少6位",
      },
    ]);
    setPasswordError(Boolean(validate));
    setPasswordHelpText(validate || "");
    setPassword(value);
  };

  const handleSignInDebounce = useDebounce(handleSignIn, HTTP_DELAY);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            登录
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            输入邮箱密码登录
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                required
                error={emailError}
                type="email"
                label="邮箱"
                variant="standard"
                value={email}
                helperText={errorHelpText}
                fullWidth
                placeholder="example@lelink.cc"
                InputLabelProps={{ shrink: true }}
                onChange={handleEmailChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                error={passwordError}
                type="password"
                label="密码"
                variant="standard"
                value={password}
                helperText={passwordHelpText}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handlePasswordChange}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;记住我
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="dark" fullWidth onClick={handleSignInDebounce}>
                登录
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                还没有账户?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  注册
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
