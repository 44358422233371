const clipbord = async (value) => {
  if (window.navigator.clipboard) {
    return window.navigator.clipboard.writeText(value);
  }
  const input = document.createElement("input");
  input.value = value;
  document.body.append(input);
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input);
  return true;
};
export default clipbord;
