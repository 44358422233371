import { useNavigate, Navigate } from "react-router-dom";

const Guard = ({ element, meta, beforeRouteEntry }) => {
  const navigate = useNavigate();

  const { pathname } = window.location;

  let ele = element;
  const pathRes = beforeRouteEntry({ pathname, meta }) ?? "";
  const pathResType = Object.prototype.toString.call(pathRes).match(/\s(\w+)]/)[1];
  if (pathResType === "Promise") {
    pathRes.then((res) => {
      if (res && res !== pathname) {
        navigate(res, { replace: true });
      }
    });
  } else if (pathRes && pathRes !== pathname) {
    ele = <Navigate to={pathRes} replace />;
  }

  return ele;
};
export default Guard;
