import MDBox from "components/MDBox/index";

import Content from "layouts/project/overview/components/ReportForms/components/Content";

function ReportForms() {
  return (
    <MDBox>
      <Content />
    </MDBox>
  );
}
export default ReportForms;
