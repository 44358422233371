/**
 * 规范
 */

const EVENT_DELAY = 400; /* 防抖 - 事件的延迟 */
const HTTP_DELAY = 400; /* 防抖 - 请求HTTP的延迟 */
const MESSAGE_DURATION_SLOW = 5000; /* message组件持续的时间 - 慢 */
const MESSAGE_DURATION_NORMAL = 3000; /* message组件默认持续的时间 */
const MESSAGE_DURATION_FAST = 800; /* message组件持续的时间 - 快 */
const MESSAGE_CLOSE_DELAY = 100; /* message组件延迟关闭 */
const HTTP_TIMEOUT = 100000; /* http请求超时 */
export {
  EVENT_DELAY,
  HTTP_DELAY,
  MESSAGE_DURATION_FAST,
  MESSAGE_DURATION_SLOW,
  MESSAGE_DURATION_NORMAL,
  MESSAGE_CLOSE_DELAY,
  HTTP_TIMEOUT,
};
