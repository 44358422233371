import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import Header from "layouts/asset/commission/components/Header";
import Content from "layouts/asset/commission/components/Content/index";

function Commission(props) {
  const { meta } = props;

  return (
    <DashboardLayout>
      <DashboardNavbar zhPath={meta.zhPath} />
      <MDBox mb={2} />
      <Header />
      <Content />
    </DashboardLayout>
  );
}

Commission.propTypes = {
  meta: PropTypes.shape({
    zhPath: PropTypes.string,
  }).isRequired,
};
export default Commission;
