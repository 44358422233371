import { useReducer, useEffect } from "react";
import { Card, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput/index";

import DataTable from "examples/Tables/DataTable";

import dataTableData from "layouts/asset/balance/data/dataTableData";

import getBalanceRecord from "api/asset/balance-reocrd";

function reducer(state, action) {
  switch (action.type) {
    case "rows": {
      return { ...state, rows: action.value };
    }
    case "pageOptions.total": {
      return { ...state, total: action.value };
    }
    case "pageOptions.numbers": {
      return { ...state, numbers: action.value };
    }
    default: {
      throw new TypeError(`不接受类型: ${action.type}`);
    }
  }
}
function reducerQuery(state, action) {
  switch (action.type) {
    case "page": {
      return { ...state, page: action.value };
    }
    case "per_page": {
      return { ...state, per_page: action.value };
    }
    default: {
      throw new TypeError(`不接受类型: ${action.type}`);
    }
  }
}

function Content() {
  const initialState = dataTableData;
  const [dataQuery, dispatchDataQuery] = useReducer(reducerQuery, {
    page: 1,
    per_page: 10,
  });
  const [data, dispatchData] = useReducer(reducer, initialState);
  const [pageOptions, dispatchPageOptions] = useReducer(reducer, {
    total: 0,
    numbers: [],
  });

  useEffect(async () => {
    const result = await getBalanceRecord(dataQuery);
    if (result) {
      dispatchData({
        type: "rows",
        value: result?.list ?? [],
      });
      dispatchPageOptions({
        type: "pageOptions.total",
        value: result?.meta?.pagination?.total ?? 0,
      });
      dispatchPageOptions({
        type: "pageOptions.numbers",
        value: new Array(result?.meta?.pagination?.total_pages ?? 0)
          .fill(0)
          .map((item, index) => index + 1),
      });
    }
  }, [dataQuery]);

  const handleEntriesPerPageChange = (value) => {
    dispatchDataQuery({ type: "per_page", value });
  };
  const handlePageIndexChange = (value) => {
    dispatchDataQuery({ type: "page", value });
  };
  return (
    <Card>
      <MDBox pb={2} px={2} pt={2}>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              余额记录
            </MDTypography>
          </Grid>
          <Grid item>
            {/* 用于占位 保持和其他页面一致 */}
            <MDInput
              sx={{
                visibility: "hidden",
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <DataTable
        table={data}
        pageOptions={pageOptions}
        entriesPerPage={false}
        showEntriesPerPage
        isSorted={false}
        entriesPerPageChange={handleEntriesPerPageChange}
        pageIndexChange={handlePageIndexChange}
      />
    </Card>
  );
}
export default Content;
