import handleUserActionSignOut from "utils/handle-user-action/sign-out";
import message from "utils/message";

import { HTTP_TIMEOUT } from "data/standard";

import handleHeaders from "./handle-headers";
import handleError from "./handleError";

const DOMAIN = process.env.REACT_APP_API_DOMAIN;
const DEFAULT_METHOD = "GET";
const siFetch = (url, init, timeout = HTTP_TIMEOUT) => {
  const headers = handleHeaders(init?.headers ?? {}, url);
  const method = init?.method ?? DEFAULT_METHOD;

  if (typeof headers.Authorization === "string" && headers.Authorization.trim().length === 0) {
    handleUserActionSignOut();
    window.navigation.navigate("/authentication/sign-in");
    return undefined;
  }

  /* 超时中止请求 */
  let requesting = true;
  const controller = new AbortController();
  const { signal } = controller;
  setTimeout(() => {
    if (requesting) controller.abort("sjkhdfkjahsdkf");
  }, timeout);

  return fetch(`${DOMAIN}${url}`, {
    ...init,
    method,
    headers,
    signal,
  })
    .then((response) => {
      if (response.status >= 500) {
        throw new Error(response.statusText);
      }
      const contentType = response.headers.get("content-type");
      if (contentType.includes("application/json")) {
        return response.json();
      }
      throw new TypeError("响应的文本类型不正确");
    })
    .then((data) => {
      if (data.status === "success") {
        return data.data;
      }
      handleError(data);
      /* 提示相关警告信息 */
      message({
        color: "warning",
        title: "警告",
        content: data.message,
      });
      return undefined;
    })
    .catch((error) => {
      /* 收集相关错误信息 */
      if (error.message === "The user aborted a request.") {
        // message({
        //   color: "error",
        //   title: "错误",
        //   content: "服务器繁忙,请稍后再试",
        // });
      } else {
        message({
          color: "error",
          title: "错误",
          content: error.message,
        });
      }
    })
    .finally(() => {
      requesting = false;
    });
};

export default siFetch;
