import { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDAvatar from "components/MDAvatar";
import Grid from "@mui/material/Grid";

// Images
// import burceMars from "assets/images/bruce-mars.jpg";
import avatarDefault from "assets/images/avatar.png";
import backgroundImage from "assets/images/bg-profile.jpeg";

import getProfileDetail from "api/profile/detail";

import { getUserInfo } from "utils/storage/local";

function Header() {
  const userInfo = getUserInfo();
  const [data, setData] = useState({
    id: userInfo?.id,
    name: userInfo?.name,
    email: userInfo?.email,
  });

  useEffect(async () => {
    const response = await getProfileDetail();
    if (response) {
      const { id, name, email } = response;
      setData({
        id,
        name,
        email,
      });
    }
  }, []);
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox lineHeight={1}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar
                src={avatarDefault}
                alt="profile-image"
                size="xl"
                shadow="sm"
                variant="rounded"
              />
            </Grid>
            <Grid item>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {data.name}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="medium">
                      UID: {data.id}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item ml={10}>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="button" fontWeight="medium" component="span">
                      Email:&nbsp;&nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                      component="span"
                    >
                      {data.email}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default Header;
