import SvgIcon from "@mui/material/SvgIcon";

function AlipayIcon() {
  return (
    <SvgIcon>
      <path
        d="M23.3064 16.0296C18.7104 14.646 16.0404 13.8216 15.2928 13.5552C16.0102 12.3106 16.5437 10.9686 16.8768 9.5712H12.96V8.2464H17.76V7.4304H12.96V5.2128H11.1168C10.7808 5.2128 10.7424 5.5104 10.7424 5.5104V7.4208H6.24V8.2368H10.7424V9.5616H7.056V10.3008H14.5248C14.2641 11.2068 13.9069 12.0823 13.4592 12.912C11.7696 12.3552 10.8288 11.9712 8.7648 11.7792C4.8576 11.4048 3.9552 13.5552 3.8112 14.8704C3.6 16.8768 5.376 18.5088 8.0256 18.5088C10.6752 18.5088 12.4416 17.28 14.1216 15.2448C15.522 15.9144 18.1272 17.0748 21.9384 18.7272C20.8396 20.3521 19.3589 21.6826 17.6262 22.6019C15.8934 23.5212 13.9615 24.0013 12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24.0014 13.373 23.7668 14.7361 23.3064 16.0296ZM7.7184 17.2416C4.9152 17.2416 4.4736 15.4656 4.6176 14.7264C4.7616 13.9872 5.5776 13.0272 7.1424 13.0272C8.9376 13.0272 10.5408 13.488 12.4704 14.4192C11.1072 16.1952 9.4464 17.2416 7.7184 17.2416Z"
        fill="#1A73E7"
      />
    </SvgIcon>
  );
}
export default AlipayIcon;
