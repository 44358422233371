import { createContext, useContext, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

const RuningState = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "SIGN_IN": {
      return { ...state, signIn: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function RuningStateProvider({ children }) {
  const initialState = {
    signIn: false,
  };
  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <RuningState.Provider value={value}>{children}</RuningState.Provider>;
}

function useRuningStateController() {
  const context = useContext(RuningState);

  if (!context) {
    throw new Error("useRuningStateController should be used inside the RuningStateProvider.");
  }

  return context;
}

RuningStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setSignIn = (dispatch, value) => dispatch({ type: "SIGN_IN", value });

export { RuningStateProvider, useRuningStateController, setSignIn };
