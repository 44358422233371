/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
// react-flatpickr components
import Flatpickr from "react-flatpickr";
// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

import formatDate from "utils/formatDate";
import { InputAdornment } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

function MDDatePicker({ input, valueState, ...rest }) {
  const [clear, setClear] = useState(false);
  const [value, setValue] = valueState;

  useEffect(() => {
    setClear(Boolean(value));
  }, [value]);

  const handleEvent = ([date]) => {
    setValue(formatDate(date));
  };
  const handleClear = () => {
    setValue("");
  };

  return (
    <Flatpickr
      {...rest}
      onChange={handleEvent}
      render={({ defaultValue }, ref) => (
        <MDInput
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
          value={value}
          InputProps={
            clear
              ? {
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={handleClear}
                    >
                      <CloseRounded />
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
      )}
    />
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
  valueState: [],
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  valueState: PropTypes.arrayOf(PropTypes.any),
};

export default MDDatePicker;
