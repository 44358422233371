/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "::scrollbar": {
    /* 盒子 */
    width: "5px",
    height: "5px",
  },
  "::scrollbar-thumb": {
    /* 滑动部位 */
    borderRadius: "5px",
    backgroundColor: `${colors.grey[800]}50`,
  },
  "::scrollbar-track": {
    /* 底部 */
    borderRadius: "5px",
    backgroundColor: `${colors.grey[500]}50`,
  },
  "::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[800]}50`,
  },
  "::-webkit-scrollbar-track": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[500]}50`,
  },
  "::-ms-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "::-ms-scrollbar-thumb": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[800]}50`,
  },
  "::-ms-scrollbar-track": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[500]}50`,
  },
  "::-moz-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "::-moz-scrollbar-thumb": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[800]}50`,
  },
  "::-moz-scrollbar-track": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[500]}50`,
  },
  "::-o-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "::-o-scrollbar-thumb": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[800]}50`,
  },
  "::-o-scrollbar-track": {
    borderRadius: "5px",
    backgroundColor: `${colors.grey[500]}50`,
  },
};

export default globals;
