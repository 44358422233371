// Material Dashboard 2 PRO React layouts
import SignIn from "layouts/authentication/sign-in";
import ProjectOverview from "layouts/project/overview";
import Balance from "layouts/asset/balance";
import Commission from "layouts/asset/commission";
import CashRecord from "layouts/asset/cash-record";
import Detail from "layouts/profile/detail";
import AgentOverview from "layouts/agent/overview";

import Icon from "@mui/material/Icon";
import ProxyIcon from "examples/CustomerIcon/Proxy";
import ProjectIcon from "examples/CustomerIcon/Project";
import AssetIcon from "examples/CustomerIcon/Asset";

const routes = [
  /* 身份验证 */
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    hide: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      /* 登入 */
      {
        name: "Sign In",
        key: "sign in",
        route: "/authentication/sign-in",
        component: <SignIn />,
      },
    ],
  },
  /* 用户中心 */
  {
    type: "collapse",
    name: "用户中心",
    key: "profile",
    icon: <Icon fontSize="medium">personRounded</Icon>,
    collapse: [
      /* 个人资料 */
      {
        name: "个人资料",
        key: "detail",
        route: "/profile/detail",
        component: <Detail meta={{ zhPath: "用户中心/个人资料" }} />,
      },
    ],
  },
  {
    type: "divider",
    key: "divider-1",
  },
  /* 项目信息 */
  {
    type: "collapse",
    name: "项目信息",
    key: "project",
    icon: <ProjectIcon color="success" />,
    collapse: [
      /* 项目总览 */
      {
        name: "项目总览",
        key: "project-overview",
        route: "/project/project-overview",
        component: <ProjectOverview meta={{ zhPath: "项目信息/项目总览" }} />,
      },
    ],
  },
  /* 我的资产 */
  {
    type: "collapse",
    name: "我的资产",
    key: "asset",
    icon: (
      <AssetIcon
        sx={{
          "&": {
            width: "22px !important",
            height: "22px !important",
          },
        }}
      />
    ),
    collapse: [
      /* 我的余额 */
      {
        name: "我的余额",
        key: "balance",
        route: "/asset/balance",
        component: <Balance meta={{ zhPath: "我的资产/我的余额" }} />,
      },
      /* 我的佣金 */
      {
        name: "我的佣金",
        key: "commission",
        route: "/asset/commission",
        component: <Commission meta={{ zhPath: "我的资产/我的佣金" }} />,
      },
      /* 提现记录 */
      {
        name: "提现记录",
        key: "cash-record",
        route: "/asset/cash-record",
        component: <CashRecord meta={{ zhPath: "我的资产/提现记录" }} />,
      },
    ],
  },
  /* 代理商 */
  {
    type: "collapse",
    name: "代理商",
    key: "agent",
    icon: <ProxyIcon />,
    collapse: [
      /* 总览 */
      {
        name: "团队总览",
        key: "agent-overview",
        route: "/agent/agent-overview",
        component: <AgentOverview meta={{ zhPath: "代理商/团队总览" }} />,
      },
    ],
  },
  /* 处理错误路由 */
  {
    hide: true,
    name: "Not Found",
    key: "not found",
    route: "*",
    component: <SignIn />,
  },
];

export default routes;
