/**
 * @description 将月份和日期格式化为两位的字符串
 * @param {string} value
 * @returns {string}
 */
const formatToTwo = (value) => {
  const source = "0x";
  if (value.length === source.length) {
    return value;
  }
  return source.replace(/x/, value);
};
const formatDate = (value, format = "YYYY-MM-DD") => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = formatToTwo(`${date.getMonth() + 1}`);
  const day = formatToTwo(`${date.getDate()}`);
  return format.replace(/YYYY/gi, year).replace(/MM/gi, month).replace(/DD/gi, day);
};
export default formatDate;
