import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import PropTypes from "prop-types";

import Header from "./components/Header";

function Detail(props) {
  const { meta } = props;

  return (
    <DashboardLayout>
      <DashboardNavbar zhPath={meta.zhPath} />
      <MDBox mb={2} />
      <Header />
      {/* 占位-让Footer组件可以贴近页面底部 */}
      <MDBox mb={58} />
    </DashboardLayout>
  );
}

Detail.propTypes = {
  meta: PropTypes.shape({
    zhPath: PropTypes.string,
  }).isRequired,
};
export default Detail;
