import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Grid, InputAdornment } from "@mui/material";

import { CloseRounded } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Select from "examples/Select";

import getOffer from "api/agent/offer";

function Query({ onQuery }) {
  const startDateState = useState("");
  const endDateState = useState("");
  const [userId, setUserId] = useState("");
  const [disableStartDate, setDisabledStartDate] = useState([]);
  const [disableEndDate, setDisabledEndDate] = useState([]);
  const [listsProject, setListsProject] = useState([]);
  const [campaignName, setCampaignName] = useState("");

  const [startDate] = startDateState;
  const [endDate] = endDateState;

  useEffect(async () => {
    const result = await getOffer();
    if (result) {
      setListsProject(result?.offers ?? []);
    }
  }, []);

  useEffect(() => {
    setDisabledStartDate([
      {
        from: endDate,
        to: "2028-12-31",
      },
    ]);
    setDisabledEndDate([
      {
        from: "2020-01-01",
        to: startDate,
      },
    ]);
  }, [startDate, endDate]);

  const handleQuery = () => {
    onQuery({
      startDate,
      endDate,
      campaignName,
      userId,
    });
  };

  const handleSelect = (list) => {
    setCampaignName(list?.offer_name ?? "");
  };

  const handleUserIdChange = (event) => {
    const { value } = event.target;
    setUserId(value.trim());
  };

  const handleClearUserId = () => {
    setUserId("");
  };
  return (
    <MDBox pb={1} px={2} pt={2}>
      <Grid container justifyContent="space-between" alignItems="center" rowSpacing={2}>
        <Grid item xs={12} lg={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            团队数据报表
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={10}>
          <Grid container spacing={1} rowSpacing={2} justifyContent="flex-end">
            <Grid
              item
              xs={6}
              md={2.6}
              lg={2}
              xl={2.1}
              xxl={1.8}
              xxxl={1.5}
              xxxxl={1.3}
              xxxxxl={1.1}
            >
              <MDDatePicker
                input={{
                  label: "开始日期",
                  sx: {
                    width: "100%",
                  },
                  search: true,
                }}
                valueState={startDateState}
                value={startDate}
                options={{
                  altFormat: "Y-m-d",
                  altInput: true,
                  disable: disableStartDate,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={2.6}
              lg={2}
              xl={2.1}
              xxl={1.8}
              xxxl={1.5}
              xxxxl={1.3}
              xxxxxl={1.1}
            >
              <MDDatePicker
                input={{
                  label: "结束日期",
                  sx: {
                    width: "100%",
                  },
                  search: true,
                }}
                valueState={endDateState}
                value={endDate}
                options={{
                  altFormat: "Y-m-d",
                  altInput: true,
                  disable: disableEndDate,
                }}
              />
            </Grid>
            <Grid item xs={6} md={2.6} lg={2} xxl={1.8} xxxl={1.5} xxxxl={1.3} xxxxxl={1.1}>
              <MDInput
                label="请输入用户ID"
                sx={{
                  width: "100%",
                }}
                value={userId}
                search
                InputProps={
                  userId
                    ? {
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={handleClearUserId}
                          >
                            <CloseRounded />
                          </InputAdornment>
                        ),
                      }
                    : null
                }
                onChange={handleUserIdChange}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={2.6}
              lg={2.5}
              xxl={2}
              xxxxl={1.8}
              xxxxxl={1.5}
              sx={{
                "& #si-select": {
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <Select
                label="选择项目"
                options={["offer_id", "offer_name"]}
                lists={listsProject}
                onSelect={handleSelect}
              />
            </Grid>
            <Grid item>
              <MDButton color="dark" variant="gradient" onClick={handleQuery}>
                查询
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Query.defaultProps = {
  onQuery: () => {},
};
Query.propTypes = {
  onQuery: PropTypes.func,
};
export default Query;
