import { useState } from "react";

import { Icon, Divider, MenuItem, Menu } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import PropTypes from "prop-types";

function Select({ lists, label, onSelect, options }) {
  const [menu, setMenu] = useState(null);
  const [labelSelect, setLabelSelect] = useState(label);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleSelect = (list) => {
    onSelect(list);
    setLabelSelect(list[options[1]]);
    setMenu(null);
  };

  const handleClear = () => {
    onSelect();
    setLabelSelect(label);
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {lists.map((list) => (
        <MenuItem key={`${list[options[0]]}`} onClick={() => handleSelect(list)}>
          {list[options[1]]}
        </MenuItem>
      ))}
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={handleClear}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          清除选择
        </MDTypography>
      </MenuItem>
    </Menu>
  );
  return (
    <MDBox display="flex">
      <MDButton
        id="si-select"
        sx={{
          width: "100%",
          fontWeight: 500,
        }}
        variant={menu ? "contained" : "outlined"}
        color="dark"
        onClick={openMenu}
      >
        {labelSelect}&nbsp;
        <Icon>keyboard_arrow_down</Icon>
      </MDButton>
      {renderMenu}
    </MDBox>
  );
}

Select.defaultProps = {
  label: "选择",
  onSelect: () => {},
  options: ["key", "value"],
};

Select.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default Select;
