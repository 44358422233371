import { useState, createRef, useMemo } from "react";
import PropTypes from "prop-types";

import { Snackbar, Divider, Typography, Box, Icon, Grid, Slide } from "@mui/material";

import boxShadows from "assets/theme/base/boxShadows";
// import boxShadowDark from "assets/theme-dark/base/boxShadow";

import borders from "assets/theme/base/borders";
// import bordersDark from "assets/theme-dark/base/borders";

import colors from "assets/theme/base/colors";
// import colorsDark from "assets/theme-dark/base/colors";

import { getTheme } from "utils/storage/local";

import { MESSAGE_CLOSE_DELAY, MESSAGE_DURATION_NORMAL } from "data/standard";

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function SiSocialbar({ content, title, color, duration }) {
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const snackbarRef = createRef();
  useMemo(() => {
    setTransition(() => TransitionLeft);
    /* 延迟处理为了让动画效果走完 */
    setTimeout(() => {
      setOpen(true);
    }, MESSAGE_CLOSE_DELAY);
  }, []);

  const titleColor = colors[color].main;
  let bgColor;
  let closeColor;
  let contentColor;
  let dividerColor;
  let icon;
  if (color === "success") {
    icon = "check_rounded";
  } else if (color === "warning") {
    icon = "star_rounded";
  } else {
    icon = "report_problem_rounded";
  }
  const dark = Boolean(getTheme().darkMode);
  if (dark) {
    bgColor = colors.dark.main;
    closeColor = colors.white.main;
    contentColor = colors.white.main;
    dividerColor = colors.white.main;
    // dividerColor = linearGradient(colors.gradients[color].main, colors.gradients[color].state);
  } else {
    bgColor = colors.white.main;
    closeColor = colors.dark.main;
    contentColor = colors.text.main;
    dividerColor = colors.text.main;
    // dividerColor = linearGradient(colors.gradients[color].main, colors.gradients[color].state);
  }

  const handleClose = () => {
    const targetParentNode = snackbarRef.current.parentNode;
    setOpen(false);
    setTimeout(() => {
      document.body.removeChild(targetParentNode);
    }, 500);
  };
  return (
    <Snackbar
      TransitionComponent={transition}
      open={open}
      ref={snackbarRef}
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiTypography-root": {
          fontFamily: '"Roboto", "SiYuanHeiTi", "Helvetica", "Arial", sans-serif',
        },
      }}
      onClose={handleClose}
    >
      <Box
        compnent="div"
        variant="contained"
        minWidth="21.875rem"
        maxWidth="21.875rem"
        backgroundColor={bgColor}
        boxShadow={boxShadows.md}
        borderRadius={borders.borderRadius.md}
        p={2}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box
              component="div"
              display="flex"
              alignItems="center"
              sx={{
                color: titleColor,
              }}
            >
              <Icon fontSize="small">{icon}</Icon>
              <Typography
                variant="button"
                sx={{
                  fontWeight: 500,
                  marginLeft: "10px",
                }}
              >
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Icon
              fontSize="small"
              sx={{
                cursor: "pointer",
                color: closeColor,
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </Grid>
        </Grid>
        <Box mt={1} mb={2}>
          <Divider
            light
            sx={{
              backgroundImage: dividerColor,
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              color: contentColor,
            }}
          >
            {content}
          </Typography>
        </Box>
      </Box>
    </Snackbar>
  );
}

SiSocialbar.defaultProps = {
  duration: MESSAGE_DURATION_NORMAL,
  color: "success",
};
SiSocialbar.propTypes = {
  duration: PropTypes.number,
  color: PropTypes.oneOf(["success", "warning", "error"]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
export default SiSocialbar;
