import { useState, useMemo } from "react";
import { Grid, Icon } from "@mui/material";

import MDBox from "components/MDBox";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import MoneyIcon from "layouts/asset/balance/components/MoneyIcon";
import CashIcon from "layouts/asset/balance/components/CashIcon";

import pxToRem from "assets/theme/functions/pxToRem";

import getBalance from "api/asset/balance";

function Header() {
  const [data, setData] = useState({
    balance: 0,
    ready_for_withdrawal: 0,
  });

  useMemo(async () => {
    const result = await getBalance();
    if (result) {
      setData({
        balance: result?.balance,
        ready_for_withdrawal: result?.ready_for_withdrawal,
      });
    }
  }, []);
  return (
    <MDBox mb={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <DefaultInfoCard
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(26)} !important`,
                }}
              >
                <MoneyIcon />
              </Icon>
            }
            title="余额"
            value={`￥${data.balance}`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <DefaultInfoCard
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(23.5)} !important`,
                }}
              >
                <CashIcon />
              </Icon>
            }
            title="可提现余额"
            value={`￥${data.ready_for_withdrawal}`}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}
export default Header;
