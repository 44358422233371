/* eslint-disable react/no-unknown-property */
import SvgIcon from "@mui/material/SvgIcon";

function WeChatIcon() {
  return (
    <SvgIcon viewBox="0 0 1024 1024">
      <path
        d="M512 1024C229.230021 1024 0 794.769979 0 512S229.230021 0 512 0s512 229.230021 512 512-229.230021 512-512 512z m304.000268-438.26556c0-84.342608-84.233124-153.023597-178.992838-153.023597-100.396008 0-179.233275 68.68099-179.233275 153.023597 0 84.558356 78.837266 152.99891 179.232201 152.99891 21.002734 0 42.24483-5.363656 63.232537-10.486876l57.861367 31.75366-15.832285-52.778935c42.216922-31.766541 73.732294-74.045719 73.732293-121.486759z m-237.133283-26.401811c-10.475069 0-20.975899-10.499757-20.975899-21.038155 0-10.475069 10.50083-21.000587 20.975899-21.000587 15.884881 0 26.397518 10.525518 26.397518 21.000587 0 10.538398-10.512637 21.038155-26.397518 21.038155z m116.014692 0c-10.48795 0-20.963019-10.499757-20.963019-21.038155 0-10.475069 10.475069-21.000587 20.963019-21.000587 15.858046 0 26.371757 10.525518 26.371757 21.000587 0 10.538398-10.765954 21.038155-26.37283 21.038155z m-75.265073-145.263094c6.901803 0 13.831514 0.481945 20.722583 1.191446-18.569392-86.866113-111.265006-151.260042-217.120202-151.260042-118.217258 0-215.219254 80.63839-215.219253 183.229451 0 59.183765 32.186231 107.842013 86.044981 145.555052l-21.444965 64.876948 75.303715-37.916981c26.928839 5.238071 48.614239 10.741266 75.315522 10.741266 6.663514 0 13.363522-0.240436 20.039916-0.925249-4.294574-14.34244-6.676394-29.572562-6.676394-45.107522-0.012881-94.030893 80.80047-170.384369 183.033023-170.384369zM503.767212 355.609224c16.200453 0 26.915958 10.741266 26.915958 26.972847 0 16.206893-10.728386 26.947086-26.917032 26.947086-16.213333 0-32.400906-10.740193-32.400905-26.947086 0.240436-16.244461 16.416201-26.97392 32.400905-26.97392z m-150.620311 53.894172c-16.213333 0-32.400906-10.727312-32.400905-26.947086 0-16.231581 16.200453-26.959966 32.400905-26.959966 16.226214 0 26.9546 10.741266 26.9546 26.959966-0.011807 15.991145-10.728386 26.948159-26.9546 26.94816z"
        fill="#07C160"
        p-id="11795"
      />
    </SvgIcon>
  );
}
export default WeChatIcon;
