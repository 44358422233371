// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

function DefaultCell({ children }) {
  return <MDBox>{children}</MDBox>;
}

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  children: null,
};

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  children: PropTypes.node,
};

export default DefaultCell;
