import PropTypes from "prop-types";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";

import Dashboard from "layouts/project/overview/components/Dashboard";
import Self from "layouts/project/overview/components/Self";
import ReportForms from "layouts/project/overview/components/ReportForms";

function Overview(props) {
  const { meta } = props;

  return (
    <DashboardLayout>
      <DashboardNavbar zhPath={meta.zhPath} />
      <MDBox mb={2} />
      <Self />
      <Dashboard />
      <ReportForms />
    </DashboardLayout>
  );
}

Overview.propTypes = {
  meta: PropTypes.shape({
    zhPath: PropTypes.string,
  }).isRequired,
};
export default Overview;
