/* eslint-disable no-empty */
const USER_INFO = "user-info"; /* 用户信息 */
const AUTHORIZATION = "authorization"; /* 验证信息 */
const THEME = "theme";

const getUserInfo = () => {
  let userInfo = null;
  try {
    userInfo = JSON.parse(localStorage.getItem(USER_INFO));
  } catch (error) {}
  return userInfo;
};

const setUserInfo = (value) => {
  try {
    localStorage.setItem(USER_INFO, JSON.stringify(value));
  } catch (error) {}
};

const getAuthorization = () => {
  let authorization = null;
  try {
    authorization = JSON.parse(localStorage.getItem(AUTHORIZATION));
  } catch (error) {}
  return authorization;
};

const getTheme = () => {
  let theme = null;
  try {
    theme = JSON.parse(localStorage.getItem(THEME));
  } catch (error) {}
  return theme;
};

const setAuthorization = (value) => {
  try {
    localStorage.setItem(AUTHORIZATION, JSON.stringify(value));
  } catch (error) {}
};

const setTheme = (value) => {
  try {
    localStorage.setItem(THEME, JSON.stringify(value));
  } catch (error) {}
};

const removeAuthorization = () => {
  localStorage.removeItem(AUTHORIZATION);
};

const removeUserInfo = () => {
  localStorage.removeItem(USER_INFO);
};

const removeTheme = () => {
  localStorage.removeItem(THEME);
};

export {
  getUserInfo,
  getAuthorization,
  getTheme,
  setUserInfo,
  setAuthorization,
  setTheme,
  removeAuthorization,
  removeUserInfo,
  removeTheme,
};
