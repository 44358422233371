import SvgIcon from "@mui/material/SvgIcon";

function DisplayIcon() {
  return (
    <SvgIcon>
      <path
        d="M5.5 12H7V8H5.5V9.25H4V10.75H5.5V12ZM8 10.75H16V9.25H8V10.75ZM13 8H14.5V6.75H16V5.25H14.5V4H13V8ZM4 6.75H12V5.25H4V6.75ZM6 18V16H2C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H18C18.55 1.69779e-06 19.021 0.196002 19.413 0.588002C19.805 0.980002 20.0007 1.45067 20 2V14C20 14.55 19.804 15.021 19.412 15.413C19.02 15.805 18.5493 16.0007 18 16H14V18H6Z"
        fill="white"
      />
    </SvgIcon>
  );
}
export default DisplayIcon;
