/* eslint-disable react/prop-types */
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import siClipbord from "utils/copy";
import message from "utils/message";
import useDebounce from "utils/debounce";
import { EVENT_DELAY, MESSAGE_DURATION_FAST } from "data/standard";

const handleCopy = async (value) => {
  await siClipbord(value);
  message({
    color: "success",
    title: "成功",
    content: "复制成功",
    duration: MESSAGE_DURATION_FAST,
  });
};

const handleCopyDebounce = useDebounce(handleCopy, EVENT_DELAY);

const dataTableData = {
  columns: [
    {
      Header: "项目编号",
      accessor: "campaign_id",
      width: "20%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "项目名称",
      accessor: "campaign_name",
      width: "20%",
      Cell: ({ value }) => (
        <CustomerCell>
          <MDTypography variant="button" fontWeight="medium" color="dark">
            {`${value || ""}`}
          </MDTypography>
        </CustomerCell>
      ),
    },
    {
      Header: "短链",
      accessor: "url",
      minWidth: "30%",
      fullWidth: true,
      Cell: ({ value }) => (
        <CustomerCell>
          <MDBox display="flex" alignItems="center" justifyContent="space-between">
            <MDTypography variant="button" fontWeight="medium" color="dark">
              {`${value || ""}`}
            </MDTypography>
            <MDButton
              size="small"
              color="secondary"
              variant="outlined"
              sx={{
                padding: "0 0.4rem",
                minWidth: "auto",
                height: "auto",
                minHeight: "auto",
                fontWeight: "300",
                marginLeft: "10px",
                whiteSpace: "nowrap",
              }}
              onClick={() => handleCopyDebounce(value)}
            >
              复制
            </MDButton>
          </MDBox>
        </CustomerCell>
      ),
    },
    {
      Header: "创建时间",
      accessor: "created_at",
      width: "30%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
  ],
  rows: [],
};

export default dataTableData;
