import { useState, useEffect } from "react";
import { Grid, Icon } from "@mui/material";

import MDBox from "components/MDBox";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import MoneyIcon from "layouts/asset/commission/components/MoneyIcon";
import RoundIcon from "layouts/asset/commission/components/RoundIcon";

import pxToRem from "assets/theme/functions/pxToRem";

import getCommission from "api/asset/commission";

function Header() {
  const [data, setData] = useState({
    revenue_total: 0,
    revenue_unsettle: 0,
  });

  useEffect(async () => {
    const result = await getCommission();
    if (result) {
      setData({
        revenue_total: result?.revenue_total ?? 0,
        revenue_unsettle: result?.revenue_unsettle ?? 0,
      });
    }
  }, []);
  return (
    <MDBox mb={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <DefaultInfoCard
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(26)} !important`,
                }}
              >
                <MoneyIcon />
              </Icon>
            }
            title="佣金总额"
            value={`￥${data.revenue_total}`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <DefaultInfoCard
            icon={
              <Icon
                sx={{
                  fontSize: `${pxToRem(20)} !important`,
                  transform: "translateY(-2px)",
                }}
              >
                <RoundIcon />
              </Icon>
            }
            title="预计待结算佣金"
            value={`￥${data.revenue_unsettle}`}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}
export default Header;
