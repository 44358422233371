/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable-loop */
import email from "./email";
import password from "./password";

const allOfRules = {
  email,
  password,
};

const keyOfRules = Object.keys(allOfRules);

/**
 * @param {node} node
 * @param {Array[rule: object]} rules
 * @returns {string | null}
 */
const formValidator = (node, rules) => {
  const { value } = node;
  let result = null;
  for (let i = 0; i < rules.length; i++) {
    if (keyOfRules.includes(rules[i].key)) {
      if (!allOfRules[rules[i].key](value)) {
        result = rules[i].errorText;
        break;
      }
    }
  }
  return result;
};

const formValidators = (nodes, rules) =>
  nodes.map((node, index) => formValidator(node, rules[index] || []));
export { formValidator, formValidators };
