/* eslint-disable no-restricted-syntax */
const handleParams = (data) => {
  let params = "?";
  if (Object.prototype.toString.call(data) !== "[object Object]") {
    return "";
  }
  for (const key in data) {
    if (data[key]) {
      params += `${key}=${data[key]}&`;
    }
  }
  return params.replace(/^[?]{1}$/, "").replace(/[&]*$/, "");
};
export default handleParams;
