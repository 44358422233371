/* eslint-disable camelcase */
import { useReducer, useEffect, useState } from "react";

import { Card } from "@mui/material";

import Query from "layouts/asset/commission/components/Content/components/Query";
import DataTable from "examples/Tables/DataTable";

import dataTableData from "layouts/asset/commission/components/Content/data/dataTableData";

import getCommissionRecord from "api/asset/commission-record";

function reducer(state, action) {
  switch (action.type) {
    case "rows": {
      return { ...state, rows: action.value };
    }
    case "pageOptions.total": {
      return { ...state, total: action.value };
    }
    case "pageOptions.numbers": {
      return { ...state, numbers: action.value };
    }
    default: {
      throw new TypeError(`不接受类型:${action.type}`);
    }
  }
}
function reducerQuery(state, action) {
  switch (action.type) {
    case "page": {
      return { ...state, page: action.value };
    }
    case "per_page": {
      return { ...state, per_page: action.value };
    }
    case "start_date": {
      return { ...state, start_date: action.value };
    }
    case "end_date": {
      return { ...state, end_date: action.value };
    }
    default: {
      throw new TypeError(`不接受类型:${action.type}`);
    }
  }
}
function Content() {
  const [dataTable, dispatchDataTable] = useReducer(reducer, dataTableData);
  const [pageOptions, dispatchPageOptions] = useReducer(reducer, {
    total: 0,
    numbers: [],
  });
  const [dataQuery, dispatchDataQuery] = useReducer(reducerQuery, {
    page: 1,
    perPage: 10,
    start_date: "",
    end_date: "",
  });
  const [total, setTotal] = useState([]);

  useEffect(async () => {
    const result = await getCommissionRecord(dataQuery);
    if (result) {
      const { total: totals, total_pages: totalPages } = result?.meta?.pagination ?? {};
      dispatchDataTable({ type: "rows", value: result?.list ?? [] });
      dispatchPageOptions({ type: "pageOptions.total", value: totals ?? 0 });
      dispatchPageOptions({
        type: "pageOptions.numbers",
        value: new Array(totalPages ?? 0).fill(0).map((item, index) => index + 1),
      });
      setTotal(
        [result?.meta?.total].map((item) => ({
          r_date: "总计>>SI<<TOTAL",
          gmv: item.gmv_total,
          revenue: item.revenue_total,
        }))
      );
    }
  }, [dataQuery]);

  const handleEntriesPerPageChange = (value) => {
    dispatchDataQuery({ type: "per_page", value });
  };
  const handlePageIndexChange = (value) => {
    dispatchDataQuery({ type: "page", value });
  };
  const handleQuery = ({ startDate: start_date, endDate: end_date }) => {
    dispatchDataQuery({ type: "start_date", value: start_date });
    dispatchDataQuery({ type: "end_date", value: end_date });
  };

  return (
    <Card>
      <Query onQuery={handleQuery} />
      <DataTable
        table={dataTable}
        total={total}
        entriesPerPage={false}
        showEntriesPerPage
        isSorted={false}
        pageOptions={pageOptions}
        entriesPerPageChange={handleEntriesPerPageChange}
        pageIndexChange={handlePageIndexChange}
      />
    </Card>
  );
}
export default Content;
