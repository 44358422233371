import { getAuthorization } from "utils/storage/local";

const handleHeaders = (headers, url) => {
  const pHeaders = { ...headers };
  /* 处理authorisation */
  if (["/api/v1/users/login"].includes(url)) {
    delete pHeaders.Authorization;
  } else {
    try {
      const { token, type } = getAuthorization();
      pHeaders.Authorization = `${type} ${token}`;
    } catch (error) {
      pHeaders.Authorization = "";
    }
  }
  /* 处理Content-Type */
  pHeaders["Content-Type"] = "application/json;charset=UTF-8";
  return pHeaders;
};
export default handleHeaders;
