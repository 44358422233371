import { SvgIcon } from "@mui/material";

function Proxy(prop) {
  return (
    <SvgIcon {...prop} viewBox="0 0 1024 1024">
      <path
        d="M512 102.4a179.2 179.2 0 1 1-179.2 179.2A179.2 179.2 0 0 1 512 102.4m0-102.4a281.6 281.6 0 1 0 281.6 281.6A281.6 281.6 0 0 0 512 0"
        p-id="5139"
      />
      <path
        d="M153.6 1024H51.2a51.2 51.2 0 0 1-51.2-51.2v-102.4a51.2 51.2 0 0 1 51.2-51.2h102.4a51.2 51.2 0 0 1 51.2 51.2v102.4a51.2 51.2 0 0 1-51.2 51.2"
        p-id="5140"
      />
      <path
        d="M972.8 1024h-102.4a51.2 51.2 0 0 1-51.2-51.2v-102.4a51.2 51.2 0 0 1 51.2-51.2h102.4a51.2 51.2 0 0 1 51.2 51.2v102.4a51.2 51.2 0 0 1-51.2 51.2"
        p-id="5141"
      />
      <path
        d="M563.2 972.8H460.8a51.2 51.2 0 0 1-51.2-51.2v-102.4a51.2 51.2 0 0 1 51.2-51.2h102.4a51.2 51.2 0 0 1 51.2 51.2v102.4a51.2 51.2 0 0 1-51.2 51.2"
        p-id="5142"
      />
      <path
        d="M512 610.2016a363.8784 363.8784 0 0 1 362.4448 337.92h102.4a465.5104 465.5104 0 0 0-929.6896 0h102.4a363.8784 363.8784 0 0 1 362.4448-337.92"
        p-id="5143"
      />
      <path d="M460.8 512h102.4v307.2H460.8z" p-id="5144" />
    </SvgIcon>
  );
}
export default Proxy;
