import { useReducer, useEffect } from "react";

import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import dataTableData from "layouts/asset/cash-record/data/dataTableData";

import getCashRecord from "api/asset/cash-record";

function reducer(state, action) {
  switch (action.type) {
    case "rows": {
      return { ...state, rows: action.value };
    }
    case "pageOptions.total": {
      return { ...state, total: action.value };
    }
    case "pageOptions.numbers": {
      return { ...state, numbers: action.value };
    }
    default: {
      throw new TypeError(`不接受类型: ${action.type}`);
    }
  }
}
function reducerQuery(state, action) {
  switch (action.type) {
    case "page": {
      return { ...state, page: action.value };
    }
    case "per_page": {
      return { ...state, per_page: action.value };
    }
    default: {
      throw new TypeError(`不接受类型: ${action.type}`);
    }
  }
}
function CashRecord(props) {
  const { meta } = props;
  const [dataQuery, dispatchDataQuery] = useReducer(reducerQuery, {
    page: 1,
    per_page: 10,
  });
  const [data, dispatchData] = useReducer(reducer, dataTableData);
  const [pageOptions, dispatchPageOptions] = useReducer(reducer, {
    total: 0,
    numbers: [],
  });

  useEffect(async () => {
    const result = await getCashRecord(dataQuery);
    if (result) {
      dispatchData({
        type: "rows",
        value: result?.list ?? [],
      });
      dispatchPageOptions({
        type: "pageOptions.total",
        value: result?.meta?.pagination?.total ?? 0,
      });
      dispatchPageOptions({
        type: "pageOptions.numbers",
        value: new Array(result?.meta?.pagination?.total_pages ?? 0)
          .fill(0)
          .map((item, index) => index + 1),
      });
    }
  }, [dataQuery]);

  const handleEntriesPerPageChange = (value) => {
    dispatchDataQuery({ type: "per_page", value });
  };
  const handlePageIndexChange = (value) => {
    dispatchDataQuery({ type: "page", value });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar zhPath={meta.zhPath} />
      <MDBox mb={2} />
      <Card>
        <DataTable
          table={data}
          pageOptions={pageOptions}
          entriesPerPage={false}
          showEntriesPerPage
          isSorted={false}
          entriesPerPageChange={handleEntriesPerPageChange}
          pageIndexChange={handlePageIndexChange}
        />
      </Card>
    </DashboardLayout>
  );
}

CashRecord.propTypes = {
  meta: PropTypes.shape({
    zhPath: PropTypes.string,
  }).isRequired,
};
export default CashRecord;
