import { useEffect, useState } from "react";
import { Grid, Icon, Card } from "@mui/material";

import { AccountCircle, ThumbUpAlt, ShoppingBasket } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import DisplayIcon from "layouts/agent/overview/components/DisplayIcon";

import pxToRem from "assets/theme/functions/pxToRem";

import getDashboard from "api/agent/dashboard";

function Header() {
  const [dataSummary, setDataSummary] = useState({
    team_member: "0",
    team_uv: "0",
    team_gmv: "0",
    team_revenue: "0",
  });
  const [dataOfferRatio, setDataOfferRatio] = useState([]);

  useEffect(async () => {
    const result = await getDashboard();
    if (result) {
      const { summary, offer_ratio: offerRatio } = result;
      setDataSummary({
        team_member: summary?.team_member ?? "0",
        team_uv: summary?.team_uv ?? "0",
        team_gmv: summary?.team_gmv ?? "0",
        team_revenue: summary?.team_revenue ?? "0",
      });
      setDataOfferRatio(offerRatio ?? []);
    }
  }, []);
  return (
    <MDBox mb={4}>
      <Grid container spacing={2} rowSpacing={4}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={12} md={6}>
              <ComplexStatisticsCard
                icon={<AccountCircle />}
                title="团队人数"
                count={`${dataSummary.team_member}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "我的直接下级人数",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ComplexStatisticsCard
                icon={
                  <Icon
                    sx={{
                      fontSize: `${pxToRem(22)} !important`,
                    }}
                  >
                    <DisplayIcon />
                  </Icon>
                }
                title="团队展现"
                count={`${dataSummary.team_uv}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "链接访问人数(UV)",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ComplexStatisticsCard
                icon={<ShoppingBasket />}
                title="团队GMV总额"
                count={`￥${dataSummary.team_gmv}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "GMV总和",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ComplexStatisticsCard
                icon={<ThumbUpAlt />}
                title="团队收益总额"
                count={`￥${dataSummary.team_revenue}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "不包含待结算收益",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <MDBox
              p={2}
              borderRadius="lg"
              position="relative"
              sx={{
                height: "100%",
              }}
            >
              <MDBox mb={3}>
                <MDTypography variant="button" fontWeight="bold">
                  项目收益占比
                </MDTypography>
              </MDBox>
              {dataOfferRatio.splice(0, 3).map((item) => (
                <MDBox mb={2} key={item.offer_id}>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="caption" color="secondary">
                      {item?.offer_name ?? ""}
                    </MDTypography>
                    <MDTypography variant="caption" color="secondary">
                      {`${((item?.ratio ?? 0) * 100).toFixed(0)}%`}
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDProgress value={(item?.ratio ?? 0) * 100} />
                  </MDBox>
                </MDBox>
              ))}
              <MDBox mb={10} />
              <MDBox position="absolute" bottom={20} right={20}>
                <MDButton color="dark" size="small" variant="gradient">
                  <MDTypography
                    fontWeight="medium"
                    variant="caption"
                    color="white"
                    textTransform="uppercase"
                  >
                    View All
                  </MDTypography>
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
export default Header;
