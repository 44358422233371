/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

function getIcon(row) {
  const { settle_status: value, date } = row;
  const [, TOTAL] = date.split(">>SI<<");
  const valueC = `${value}`;
  if (TOTAL === "TOTAL") {
    return <DefaultCell value="不含未结算收益" fontWeight="bold" />;
  }
  return (
    <StatusCell
      icon={valueC === "0" ? "sync" : "done"}
      color={valueC === "0" ? "secondary" : "success"}
      status={
        valueC === "0" ? (
          <MDBox
            component="span"
            sx={(theme) => ({
              textDecoration: "underline",
              fontWeight: 400,
              color: theme.palette.grey[600],
            })}
          >
            预计收益
          </MDBox>
        ) : (
          ""
        )
      }
    />
  );
}
const dataTableData = {
  columns: [
    {
      Header: "日期",
      accessor: "date",
      width: 120,
      Cell: ({ value }) => {
        const [valueC, TOTAL] = value.split(">>SI<<");
        return (
          <DefaultCell
            value={`${valueC || ""}`}
            fontWeight={TOTAL === "TOTAL" ? "bold" : "medium"}
          />
        );
      },
    },
    {
      Header: "项目编号",
      accessor: "campaign_id",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "项目名称",
      accessor: "campaign_name",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "展现",
      accessor: "uv",
      align: "right",
      width: 50,
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "有效转化",
      accessor: "action_data",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "有效转化率(CTR)",
      accessor: "ctr",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0%"}`} />,
    },
    {
      Header: "转化单价(CPC)",
      accessor: "avg_price_action",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "千展单价(RPM)",
      accessor: "rpm",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "GMV",
      accessor: "gmv",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "佣金比例",
      accessor: "s_ratio",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0%"}`} />,
    },
    {
      Header: "收益",
      accessor: "revenue",
      align: "right",
      width: 120,
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "结算状态",
      accessor: "settle_status",
      width: 120,
      Cell: ({ row: { values } }) => getIcon(values),
    },
  ],
  rows: [],
};

export default dataTableData;
