/* eslint-disable react/prop-types */
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";

const dataTableData = {
  columns: [
    {
      Header: "时间",
      accessor: "c_time",
      width: "15%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "变动金额",
      accessor: "balance_change",
      align: "right",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    {
      Header: "增减",
      accessor: "b_in_out",
      align: "center",
      width: "20%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
    {
      Header: "发生后余额",
      accessor: "balance",
      align: "right",
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={`${value || "0"}`} />,
    },
    /* 占位 */
    {
      Header: "",
      accessor: "seat1",
      align: "right",
      width: "10%",
      Cell: () => "",
    },
    {
      Header: "变动说明",
      accessor: "description",
      minWidth: "25%",
      Cell: ({ value }) => <DefaultCell value={`${value || ""}`} />,
    },
  ],

  rows: [],
};

export default dataTableData;
