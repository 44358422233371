import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";

import { Grid } from "@mui/material";

import Select from "examples/Select";

import getSelf from "api/project/self";

function Query({ onQuery }) {
  const startDateState = useState("");
  const endDateState = useState("");
  const [disableStartDate, setDisabledStartDate] = useState([]);
  const [disableEndDate, setDisabledEndDate] = useState([]);
  const [listsProject, setListsProject] = useState([]);
  const [campaignId, setCampaignId] = useState("");

  const [startDate] = startDateState;
  const [endDate] = endDateState;

  useEffect(async () => {
    const result = await getSelf();
    if (result) {
      setListsProject(result?.campaigns ?? []);
    }
  }, []);

  useEffect(() => {
    setDisabledStartDate([
      {
        from: endDate,
        to: "2028-12-31",
      },
    ]);
    setDisabledEndDate([
      {
        from: "2020-01-01",
        to: startDate,
      },
    ]);
  }, [startDate, endDate]);

  const handleQuery = () => {
    onQuery({
      startDate,
      endDate,
      campaignId,
    });
  };
  const handleSelect = (list) => {
    setCampaignId(list?.campaign_id ?? "");
  };

  return (
    <MDBox pb={2} px={2} pt={2}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            项目报表
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid
              item
              xs={6}
              sm={3}
              md={2.7}
              lg={2.1}
              xxl={1.8}
              xxxl={1.5}
              xxxxl={1.3}
              xxxxxl={1.2}
            >
              <MDDatePicker
                input={{
                  sx: {
                    width: "100%",
                  },
                  search: true,
                  label: "开始日期",
                }}
                valueState={startDateState}
                value={startDate}
                options={{
                  altFormat: "Y-m-d",
                  altInput: true,
                  disable: disableStartDate,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              md={2.7}
              lg={2.1}
              xxl={1.8}
              xxxl={1.5}
              xxxxl={1.3}
              xxxxxl={1.2}
            >
              <MDDatePicker
                input={{
                  sx: {
                    width: "100%",
                  },
                  search: true,
                  label: "结束日期",
                }}
                valueState={endDateState}
                value={endDate}
                options={{
                  altFormat: "Y-m-d",
                  altInput: true,
                  disable: disableEndDate,
                  shorthandCurrentMonth: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2.5}
              xxl={2.2}
              xxxxl={1.8}
              xxxxxl={1.5}
              sx={{
                "& #si-select": {
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <Select
                label="选择项目"
                options={["campaign_id", "campaign_name"]}
                lists={listsProject}
                onSelect={handleSelect}
              />
            </Grid>
            <Grid item xs={6} sm="auto">
              <MDButton color="dark" variant="gradient" onClick={handleQuery}>
                查询
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Query.defaultProps = {
  onQuery: () => {},
};
Query.propTypes = {
  onQuery: PropTypes.func,
};
export default Query;
