import { useState, useEffect, useReducer } from "react";

import { Icon, Card, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";

import Map from "layouts/project/overview/components/Self/components/Map";

import dataTableData from "layouts/project/overview/components/Self/data/dataTableData";

import getSelf from "api/project/self";

function reducer(state, action) {
  switch (action.type) {
    case "rows": {
      return { ...state, rows: action.value };
    }
    default: {
      throw new TypeError(`不接受类型: ${action.type}`);
    }
  }
}

function Self() {
  const { innerWidth } = window;
  const showVectorMapThreshold = 1400;
  const [isShowVectorMap] = useState(innerWidth >= showVectorMapThreshold);

  const initialState = dataTableData;

  const [data, dispatch] = useReducer(reducer, initialState);
  useEffect(async () => {
    const result = await getSelf();
    if (result) {
      dispatch({
        type: "rows",
        value: result?.campaigns ?? [],
      });
    }
  }, []);
  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            language
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          项目列表
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} xxl={7}>
            <DataTable
              tableSx={{
                "& tr .MuiBox-root": {
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                },
                "tr .MuiTypography-root": {
                  fontWeight: 500,
                },
                "tr:last-of-type td": {
                  border: "none",
                },
              }}
              table={data}
              needPagination={false}
              entriesPerPage={false}
              isSorted={false}
            />
          </Grid>
          {isShowVectorMap && (
            <Grid item xxl={5} sx={{ mt: { xs: 5, lg: 0 } }}>
              <Map />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}
export default Self;
